import { BridgeComponent } from '@hotwired/hotwire-native-bridge'

import { fromByteArray } from 'base64-js'

import getFileStream from '../../services/downloadFiles'
import { DownloadFileEventDetails } from '../file_details_controller'
interface PrepareMessageData {
  fileId: string
  fileName: string
  size: number
}

interface ChunkMessageData {
  chunk: string
}

interface CompleteMessageData {}

interface ResultData {
  success: boolean
  message?: string
}

/**
 * BridgeDownloadFileController is a bridge component that allows to download files from medical records
 *
 * It works together with `file-details` controller to download a file
 *
 * <div data-controller="file-details bridge--download-file" data-action="file-details:download->bridge--download-file#download">
 *   ...
 *   <button data-action="file-details#download">Download</button>
 * </div>
 *
 * The controller requires the respective bridge component to be implemented and handle the following events:
 *
 * - `prepare` with arguments `fileId`, `fileName`, `size`
 * - `chunk` with arguments `chunk` (Base64 encoded string) [called multiple times]
 * - `complete` - this should render a UI to the user to show the download is complete and the user can now open the file
 */
export default class BridgeDownloadFileController extends BridgeComponent<HTMLFormElement> {
  static component = 'bridge--download-file'

  async download(event: CustomEvent<DownloadFileEventDetails>) {
    const { fileId, fileName, fileSize, encryptionKey } = event.detail

    const fileStream = await getFileStream(fileId, encryptionKey)

    await this.prepareDownload(fileId, fileName, fileSize)

    // Safari cannot use for await (const chunk of fileStream)....
    const reader = fileStream.getReader()
    while (true) {
      const { done, value } = await reader.read()
      if (done) break
      await this.sendFileChunk(value)
    }
    await reader.cancel()

    await this.completeDownload()
  }

  private prepareDownload(fileId: string, fileName: string, size: number) {
    return new Promise<ResultData>((resolve, reject) => {
      this.send<PrepareMessageData, ResultData>('prepare', { fileId, fileName, size }, resolve)
    })
  }

  private sendFileChunk(chunk: Uint8Array) {
    return new Promise<ResultData>((resolve, reject) => {
      this.send<ChunkMessageData, ResultData>('chunk', { chunk: fromByteArray(chunk) }, resolve)
    })
  }

  private completeDownload() {
    return new Promise<ResultData>((resolve, reject) => {
      this.send<CompleteMessageData, ResultData>('complete', {}, resolve)
    })
  }
}
