import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  connect() {
    super.connect()
    // opt out of page caching so that new page (not the cached one) is rendered on mobile App
    Turbo.cache.exemptPageFromCache()
  }
}
