import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { exportEncryptionKey } from '@samedi/crypto-js/crypto'
import {
  Key as SiSymmetricKey,
  generateSessionKey,
  loadSessionKey,
} from '@samedi/crypto-js/subtle/symmetric'

import { t } from 'translations/i18n'

import { buildSigner } from '../encryption/dataSigning'
import {
  CSRFToken,
  decryptSessionData,
  encryptSessionData,
  getPatientKeyOnce,
  loadDeviceKeyFromSessionOnce,
} from '../encryption/encryption'
import { encryptInstitutionKeyData } from '../encryption/encryptionKeyHandling'
import {
  importPatientUserKeyPairPublicKey,
  loadEncryptedSessionKey,
} from '../encryption/keyHandling'
import { OrbeonFormInstanceWrapper } from '../services/OrbeonFormInstanceWrapper'

interface SubmitEvent {
  params: {
    draft: boolean
  }
}

interface EncryptedPatientFormDataForSubmit {
  form_data: String
  patient_signature: String
  session_key_patient: String
  session_key_patient_key_pair_id: String
  session_key_institution: String
}

interface InstitutionPublicKey {
  id: String
  publicExponent: String
  modulus: String
}

export default class extends Controller {
  static targets = ['initializationForm', 'orbeonFrame']
  static values = {
    patientFormId: String,
    encryptedFormData: String,
    sessionKey: String,
    sessionKeyPatientKeyPairId: String,
    institutionId: String,
  }

  public siSessionKey: SiSymmetricKey | null = null

  declare readonly initializationFormTarget: HTMLFormElement
  declare readonly orbeonFrameTarget: HTMLIFrameElement

  declare readonly patientFormIdValue: string
  declare readonly encryptedFormDataValue: string
  declare readonly institutionIdValue: string
  declare readonly sessionKeyValue: string
  declare readonly sessionKeyPatientKeyPairIdValue: string

  frameResizeObserver: ResizeObserver | null = null

  async connect() {
    await loadDeviceKeyFromSessionOnce()
    this.siSessionKey = this.sessionKeyValue
      ? await loadEncryptedSessionKey(this.sessionKeyValue, this.sessionKeyPatientKeyPairIdValue)
      : null

    await this.setInitialFormData()
    this.initializationFormTarget.requestSubmit()
  }

  orbeonFrameTargetConnected(frame: HTMLIFrameElement) {
    window.addEventListener('beforeprint', this.handleBeforePrint)
    window.addEventListener('afterprint', this.handleResize)
    frame.addEventListener('load', this.handleLoad, { passive: true })
    this.frameResizeObserver = new ResizeObserver(this.handleResize.bind(this))
  }

  orbeonFrameTargetDisconnect(frame: HTMLIFrameElement) {
    window.removeEventListener('beforeprint', this.handleBeforePrint)
    window.removeEventListener('afterprint', this.handleResize)
    frame.removeEventListener('load', this.handleLoad)
    this.frameResizeObserver?.disconnect()
    this.frameResizeObserver = null
  }

  async setInitialFormData() {
    if (this.encryptedFormDataValue && this.siSessionKey) {
      try {
        const data = JSON.parse(
          await decryptSessionData(this.encryptedFormDataValue, this.siSessionKey),
        )
        this.setFormData(data.data, data.defaults)
        return
      } catch (e) {
        this.showError(t('patient_forms.errors.loading'), e)
      }
    }

    // There is no initial form data.
    // This could be because the patient form was created for an appointment
    // and was initialized in the platform backend.
    this.setFormData('')
  }

  setFormData(value: string, defaults?: string) {
    const formDataInput = this.initializationFormTarget.elements.namedItem('form_data')
    if (!formDataInput || !(formDataInput instanceof HTMLInputElement)) {
      throw new Error('Invalid form. "form_data" field not present.')
    }

    formDataInput.value = `fr-form-data=${encodeURIComponent(value)}`
    if (defaults) {
      formDataInput.value += `&defaults=${encodeURIComponent(defaults)}`
    }
  }

  async submit(event: SubmitEvent) {
    try {
      const orbeonFormInstanceWrapper = new OrbeonFormInstanceWrapper(this.orbeonFrameTarget)
      const isDraft = Boolean(event.params.draft)
      const isValid = await orbeonFormInstanceWrapper.isValid()

      if (!isDraft && !isValid) {
        this.showError(t('patient_forms.errors.invalid'))
        return
      }

      const formDataToEncrypt = await orbeonFormInstanceWrapper.getCurrentFormDataEncodedString()
      const encryptedDataToSubmit = await this.encryptPatientForm(formDataToEncrypt)
      const response = await this.putPatientForm(encryptedDataToSubmit, isDraft)

      if (response.ok) {
        const responseBody = await response.json()

        Turbo.visit(responseBody.redirect_url, { action: 'replace' })
      } else {
        console.error(response)
        throw new Error('Server response is not ok.')
      }
    } catch (e) {
      this.showError(t('patient_forms.errors.submitting'), e)
    }
  }

  async getSessionKey(): Promise<SiSymmetricKey> {
    if (!this.siSessionKey) {
      this.siSessionKey = await this.generateSessionKey()
    }

    return this.siSessionKey
  }

  async generateSessionKey(): Promise<SiSymmetricKey> {
    const sessionKeyBytes = generateSessionKey()
    return loadSessionKey(sessionKeyBytes)
  }

  async fetchInstitutionPublicKey(): Promise<InstitutionPublicKey> {
    const request = await fetch(`/api/frontend/institutions/${this.institutionIdValue}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    const response = await request.json()
    const { id, public_exponent: publicExponent, modulus } = response.data.key_pair

    return { id, publicExponent, modulus }
  }

  async encryptSessionKeyForPatient() {
    const patientKeyPair = await getPatientKeyOnce()
    const patientPublicKey = await importPatientUserKeyPairPublicKey(patientKeyPair.plain)
    const sessionKeyBase = (await this.getSessionKey()).base
    const encryptedSessionKeyPatient = await exportEncryptionKey(sessionKeyBase, patientPublicKey)
    return { encryptedSessionKeyPatient, patientKeyPairId: patientKeyPair.id }
  }

  async encryptSessionKeyForInstitution() {
    const sessionKeyBase = (await this.getSessionKey()).base
    const institutionPublicKey = await this.fetchInstitutionPublicKey()
    return encryptInstitutionKeyData(sessionKeyBase, institutionPublicKey)
  }

  async encryptPatientForm(formDataToEncrypt: string): Promise<EncryptedPatientFormDataForSubmit> {
    const encryptedFormData = await encryptSessionData(
      formDataToEncrypt,
      await this.getSessionKey(),
    )

    const signer = await buildSigner()
    const patientSignature = await signer.signData(encryptedFormData, '', new Date())

    const { encryptedSessionKeyPatient, patientKeyPairId } =
      await this.encryptSessionKeyForPatient()
    const encryptedSessionKeyInstitution = await this.encryptSessionKeyForInstitution()

    return {
      form_data: encryptedFormData,
      patient_signature: patientSignature,
      session_key_patient: encryptedSessionKeyPatient,
      session_key_patient_key_pair_id: patientKeyPairId,
      session_key_institution: encryptedSessionKeyInstitution,
    }
  }

  async putPatientForm(
    encryptedPatientFormData: EncryptedPatientFormDataForSubmit,
    isDraft: boolean,
  ) {
    const submitRequest = await fetch(`/patient_forms/${this.patientFormIdValue}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': CSRFToken(),
      },
      body: JSON.stringify({
        patient_form: {
          draft: isDraft,
          ...encryptedPatientFormData,
        },
      }),
    })

    return submitRequest
  }

  showError(message: string, error?: unknown) {
    error && console.error(error)
    alert(message) // eslint-disable-line no-alert
  }

  handleLoad = () => {
    const document = this.orbeonFrameTarget.contentDocument
    if (document) {
      this.frameResizeObserver?.observe(document.body)
    }
    this.handleResize()
  }

  handleResize = () => {
    const document = this.orbeonFrameTarget.contentDocument
    if (document) {
      this.orbeonFrameTarget.style.height = `${document.documentElement.offsetHeight}px`
    } else {
      this.orbeonFrameTarget.style.height = '50px'
    }
  }

  handleBeforePrint = () => {
    const frame = this.orbeonFrameTarget

    /*
      Set the iframe width to below the mobile-breakpoint, because that causes
      the form to be longer.
      For a DIN A4 page, the mobile layout is chosen. For wider pages, there
      will be additional empty whitespace at the bottom, but no form-part is
      clipped away.
    */
    frame.style.width = '600px'
    this.handleResize()
    frame.style.width = ''
  }
}
