// Code generated by `rails fontawesome:update`. DO NOT EDIT.
import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

// Add all free regular icons for customization of external pages
import { far as freeRegularIcons } from '@fortawesome/free-regular-svg-icons'

import {
  faAddressCard as farAddressCard,
  faArrowDown as farArrowDown,
  faArrowRightFromBracket as farArrowRightFromBracket,
  faArrowRightToBracket as farArrowRightToBracket,
  faArrowUpRightFromSquare as farArrowUpRightFromSquare,
  faBarcodeRead as farBarcodeRead,
  faBars as farBars,
  faCalendar as farCalendar,
  faCalendarDay as farCalendarDay,
  faCheck as farCheck,
  faCheckCircle as farCheckCircle,
  faChevronDown as farChevronDown,
  faChevronRight as farChevronRight,
  faChevronUp as farChevronUp,
  faCircle as farCircle,
  faCircleCheck as farCircleCheck,
  faCircleDot as farCircleDot,
  faCircleExclamation as farCircleExclamation,
  faCircleInfo as farCircleInfo,
  faCircleQuestion as farCircleQuestion,
  faCircleUser as farCircleUser,
  faCircleXmark as farCircleXmark,
  faClipboardList as farClipboardList,
  faClose as farClose,
  faComments as farComments,
  faDesktop as farDesktop,
  faEllipsis as farEllipsis,
  faEnvelope as farEnvelope,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faFile as farFile,
  faFileImport as farFileImport,
  faFolder as farFolder,
  faFolderMedical as farFolderMedical,
  faGlobe as farGlobe,
  faHeart as farHeart,
  faHeartCrack as farHeartCrack,
  faHome as farHome,
  faHospital as farHospital,
  faKeySkeleton as farKeySkeleton,
  faListCheck as farListCheck,
  faLocationDot as farLocationDot,
  faLock as farLock,
  faMagnifyingGlass as farMagnifyingGlass,
  faMagnifyingGlassMinus as farMagnifyingGlassMinus,
  faMagnifyingGlassPlus as farMagnifyingGlassPlus,
  faMobile as farMobile,
  faNotebook as farNotebook,
  faPalette as farPalette,
  faPaperPlane as farPaperPlane,
  faPaperclip as farPaperclip,
  faPhone as farPhone,
  faPlus as farPlus,
  faPrint as farPrint,
  faRotate as farRotate,
  faShare as farShare,
  faShareNodes as farShareNodes,
  faShieldVirus as farShieldVirus,
  faSignsPost as farSignsPost,
  faSpinnerThird as farSpinnerThird,
  faTablet as farTablet,
  faUnlock as farUnlock,
  faUpload as farUpload,
  faUser as farUser,
  faUserDoctor as farUserDoctor,
  faUsers as farUsers,
  faVideo as farVideo,
  faXmark as farXmark
} from '@fortawesome/pro-regular-svg-icons'

import {
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faCircleCheck as fasCircleCheck,
  faCircleXmark as fasCircleXmark,
  faCopy as fasCopy,
  faTrashCan as fasTrashCan
} from '@fortawesome/pro-solid-svg-icons'


config.mutateApproach = 'sync'

// Add all used icons in here!
library.add(
  freeRegularIcons,
  farAddressCard,
  farArrowDown,
  farArrowRightFromBracket,
  farArrowRightToBracket,
  farArrowUpRightFromSquare,
  farBarcodeRead,
  farBars,
  farCalendar,
  farCalendarDay,
  farCheck,
  farCheckCircle,
  farChevronDown,
  farChevronRight,
  farChevronUp,
  farCircle,
  farCircleCheck,
  farCircleDot,
  farCircleExclamation,
  farCircleInfo,
  farCircleQuestion,
  farCircleUser,
  farCircleXmark,
  farClipboardList,
  farClose,
  farComments,
  farDesktop,
  farEllipsis,
  farEnvelope,
  farEye,
  farEyeSlash,
  farFile,
  farFileImport,
  farFolder,
  farFolderMedical,
  farGlobe,
  farHeart,
  farHeartCrack,
  farHome,
  farHospital,
  farKeySkeleton,
  farListCheck,
  farLocationDot,
  farLock,
  farMagnifyingGlass,
  farMagnifyingGlassMinus,
  farMagnifyingGlassPlus,
  farMobile,
  farNotebook,
  farPalette,
  farPaperPlane,
  farPaperclip,
  farPhone,
  farPlus,
  farPrint,
  farRotate,
  farShare,
  farShareNodes,
  farShieldVirus,
  farSignsPost,
  farSpinnerThird,
  farTablet,
  farUnlock,
  farUpload,
  farUser,
  farUserDoctor,
  farUsers,
  farVideo,
  farXmark,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasCircleCheck,
  fasCircleXmark,
  fasCopy,
  fasTrashCan
)

dom.watch()
