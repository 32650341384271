import { Controller } from '@hotwired/stimulus'
import { generateAuthKeyFromPassword } from 'encryption/encryption'
import {
  exportDeviceKeyPrivateKey,
  exportDeviceKeyPublicKey,
  exportPatientKeyPrivateKey,
  exportPatientUserKeyPairPublicKey,
  exportPatientUserMasterKey,
  generateDeviceKeyPair,
  generatePatientKeyPair,
  generatePatientUserMasterKey,
} from 'encryption/keyHandling'

// This controller is used for resetting encryption for the account
// It generates new encryption keys and injects them into the password changing form
//
// WARNING: Make sure reset-password#procceedWithoutRecoveryKey is called after keys injection!
//          This is needed for C5 compliance for PSS-07
export default class ResetAccountEncryptionController extends Controller<HTMLFormElement> {
  async injectNewEncyptionKeys(event: CustomEvent) {
    event.preventDefault()

    const password = event.detail.fetchOptions.body.get('patient_user[password]')

    const { deviceKeyDetails, masterKeyDetails, patientKeyDetails } =
      await this.generateEncryptionKeys(password)

    event.detail.fetchOptions.body.set('device_key_details', JSON.stringify(deviceKeyDetails))
    event.detail.fetchOptions.body.set('master_key_details', JSON.stringify(masterKeyDetails))
    event.detail.fetchOptions.body.set('patient_key_details', JSON.stringify(patientKeyDetails))

    // Everything here is async. We need to inform next controller that we are done
    // Next controller: app/javascript/controllers/reset_password_controller.ts (reset-password#procceedWithoutRecoveryKey)
    this.dispatch('keys-injected', { detail: { event } })
  }

  private async generateEncryptionKeys(password: string) {
    const deviceKeyDetails = await this.generateDeviceKey(password)
    const masterKeyDetails = await this.generateMasterKey(deviceKeyDetails.key)
    const patientKeyDetails = await this.generatePatientKeyPair(masterKeyDetails.key)

    return {
      deviceKeyDetails: deviceKeyDetails.exportable,
      masterKeyDetails: masterKeyDetails.exportable,
      patientKeyDetails,
    }
  }

  private async generateDeviceKey(password: string) {
    const baseKey = await generateAuthKeyFromPassword(password)
    const deviceKeyPair = await generateDeviceKeyPair()

    const exportedPrivateKey = await exportDeviceKeyPrivateKey(
      baseKey.key,
      deviceKeyPair.privateKey,
    )
    const exportedPublicKey = await exportDeviceKeyPublicKey(deviceKeyPair.publicKey)

    return {
      key: deviceKeyPair,
      exportable: {
        public_key: exportedPublicKey,
        private_key: exportedPrivateKey,
        pbkdf_salt: baseKey.pbkdfSalt,
        pbkdf_iterations: baseKey.pbkdfIterations,
      },
    }
  }

  private async generateMasterKey(deviceKey: CryptoKeyPair) {
    const patientUserMasterKey = await generatePatientUserMasterKey()

    const encryptedPatientUserMasterKey = await exportPatientUserMasterKey(
      deviceKey.publicKey,
      patientUserMasterKey,
    )

    return {
      key: patientUserMasterKey,
      exportable: {
        patient_user_master_key_encrypted: encryptedPatientUserMasterKey,
      },
    }
  }

  private async generatePatientKeyPair(patientUserMasterKey: CryptoKey) {
    const patientKeyPair = await generatePatientKeyPair()

    const exportedPrivateKey = await exportPatientKeyPrivateKey(
      patientUserMasterKey,
      patientKeyPair.privateKey,
    )
    const exportedPublicKey = await exportPatientUserKeyPairPublicKey(patientKeyPair.publicKey)

    return {
      public_key: exportedPublicKey,
      private_key: exportedPrivateKey,
    }
  }
}
