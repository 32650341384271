import { BridgeComponent } from '@hotwired/hotwire-native-bridge'

import { t } from '../../translations/i18n'
import { JSX } from 'services/jsx/jsx-runtime'

interface TenantData {
  id: string
  domain: string
  name: string
  img: string
}

type ResponseDataFromAndroid = Array<TenantData>

type ResponseDataFromIOS = {
  data: Array<TenantData>
}

const isResponseDataFromIOS = (
  data: ResponseDataFromAndroid | ResponseDataFromIOS,
): data is ResponseDataFromIOS => 'data' in data

// Native bridge for handling "Recently Visited" list of tenants
export default class extends BridgeComponent<HTMLDivElement> {
  static component = 'bridge--recently-visited-tenants'
  static targets = ['recentlyVisited', 'tenant']

  connect() {
    super.connect()

    // We need to ask the mobile app for the list of tenants
    this.send<{}, { data: ResponseDataFromAndroid | ResponseDataFromIOS }>(
      'connect',
      {},
      ({ data }) => {
        let tenantData = isResponseDataFromIOS(data) ? data.data : data
        const tenants = this.filterTenants(tenantData)

        if (tenants.length > 0) {
          this.recentlyVisitedTarget.replaceChildren(this.buildTenantListHTML(tenants))
        }
      },
    )
  }

  private filterTenants(tenants: Array<TenantData>): Array<TenantData> {
    return tenants.filter((tenant) => {
      const availableTenant = this.tenantTargets.find(
        (target) => target.dataset.tenantId === tenant.id,
      )

      return availableTenant && availableTenant.dataset.hideOnMobile === 'false'
    })
  }

  private buildTenantListHTML(tenants: Array<TenantData>): NonNullable<JSX.Element> {
    return (
      <div className="bg-gradient-to-b from-white to-transparent p-2 border-t border-info-300 rounded-[24px] mb-4">
        <h3 className="pt-6 pb-3 text-center text-text-heavy-desktop-h3 text-info-900">
          {t('tenant_selector.visited_title')}
        </h3>
        <div className="flex flex-wrap gap-3 justify-center items-start">
          {tenants.map((tenant) => (
            <a
              className="w-[calc(50%-6px)] relative pt-10 px-3.5 pb-2 mt-11 rounded-m bg-primary-100 shadow-sm text-center text-text-heavy-desktop-h4 leading-none border border-primary-150 no-underline flex"
              href={tenant.domain}
              data-controller="bridge--pick-tenant"
              data-bridge--pick-tenant-tenant-id-value={tenant.id}
              data-bridge--pick-tenant-tenant-name-value={tenant.name}
              data-bridge--pick-tenant-tenant-domain-value={tenant.domain}
              data-bridge--pick-tenant-tenant-img-value={tenant.img}
              data-action="click->bridge--pick-tenant#selected"
            >
              {tenant.img ? (
                <img
                  className="w-16 h-16 rounded-m bg-info-100 border border-primary-200 absolute -top-8 left-[calc(50%-32px)]"
                  src={tenant.img}
                />
              ) : (
                <div className="w-16 h-16 bg-secondary-500 rounded-m border border-primary-200 absolute -top-8 left-[calc(50%-32px)]"></div>
              )}
              <div className="w-full min-h-[56px] flex">
                <div className="self-center w-full break-words hyphens-auto" lang="de">
                  {tenant.name}
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    )!
  }

  declare recentlyVisitedTarget: HTMLElement
  declare readonly tenantTargets: HTMLElement[]
}
