import { Controller } from '@hotwired/stimulus'

import { isHotwireNativeApp } from '../isHotwireNativeApp'
import downloadFileFromStream from '../services/downloadFileFromStream'
import getFileStream from '../services/downloadFiles'

import { DownloadFileEventDetails } from './file_details_controller'
/**
 * DownloadFileController allow to download files from medical records in the browser
 *
 * It works together with `file-details` controller to download a file
 *
 * <div data-controller="file-details download-file" data-action="file-details:download->download-file#download">
 *   ...
 *   <button data-action="file-details#download">Download</button>
 * </div>
 *
 * It does not do anything on Hotwire Native applications
 */
export default class DownloadFileController extends Controller<HTMLFormElement> {
  async download(event: CustomEvent<DownloadFileEventDetails>) {
    // Hotwire Native App implements this via `bridge--download-file` controller
    if (isHotwireNativeApp()) {
      return
    }

    const { fileId, fileName, fileSize, encryptionKey } = event.detail

    const fileStream = await getFileStream(fileId, encryptionKey)

    downloadFileFromStream(fileStream, {
      filename: fileName || 'unkown-file-name',
      size: fileSize,
    })
  }
}
