// Before leaving a page, turbo will cache the DOM for retrieving it later on.
// This includes also the menu checkbox state, which can lead to the menu being shown on mobile
// when returning to a page.
// This hook ensures the menu is hidden before turbo caches the page.
document.addEventListener('turbo:before-cache', (event) => {
  const menuCheckbox = document.getElementById('show-menu') as HTMLInputElement
  if (menuCheckbox) {
    menuCheckbox.checked = false
  }
})
