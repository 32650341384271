{
  "devise.password_change.form.password_invalid": "Passwort erfüllt die Anforderungen nicht",
  "devise.password_change.form.password_match": "Passwörter stimmen überein",
  "devise.password_change.form.password_mismatch": "Passwörter stimmen nicht überein",
  "devise.password_change.form.password_valid": "Passwort erfüllt die Anforderungen",
  "document_upload.file_error_title": "Dieses Dokument kann nicht hochgeladen werden",
  "document_upload.file_type_error": "Überprüfen Sie bitte, ob das Dokument ein kompatibles Dateiformat hat.",
  "document_upload.upload_error": "Während des Hochladevorgangs ist ein Netzwerkfehler aufgetreten. Bitte versuchen Sie, das Dokument erneut hochzuladen.",
  "document_upload.upload_error_title": "Ein Fehler ist aufgetreten",
  "medication_plan.dosage_unit_codes.#": "Messlöffel",
  "medication_plan.dosage_unit_codes.0": "Messbecher",
  "medication_plan.dosage_unit_codes.1": "Stück",
  "medication_plan.dosage_unit_codes.2": "Pkg.",
  "medication_plan.dosage_unit_codes.3": "Flasche",
  "medication_plan.dosage_unit_codes.4": "Beutel",
  "medication_plan.dosage_unit_codes.5": "Hub",
  "medication_plan.dosage_unit_codes.6": "Tropfen",
  "medication_plan.dosage_unit_codes.7": "Teelöffel",
  "medication_plan.dosage_unit_codes.8": "Esslöffel",
  "medication_plan.dosage_unit_codes.9": "E",
  "medication_plan.dosage_unit_codes.A": "Tasse",
  "medication_plan.dosage_unit_codes.B": "Applikatorfüllung",
  "medication_plan.dosage_unit_codes.C": "Augenbadewanne",
  "medication_plan.dosage_unit_codes.D": "Dosierbriefchen",
  "medication_plan.dosage_unit_codes.E": "Dosierpipette",
  "medication_plan.dosage_unit_codes.F": "Dosierspritze",
  "medication_plan.dosage_unit_codes.G": "Einzeldosis",
  "medication_plan.dosage_unit_codes.H": "Glas",
  "medication_plan.dosage_unit_codes.I": "Likörglas",
  "medication_plan.dosage_unit_codes.J": "Messkappe",
  "medication_plan.dosage_unit_codes.K": "Messschale",
  "medication_plan.dosage_unit_codes.L": "Mio E",
  "medication_plan.dosage_unit_codes.M": "Mio IE",
  "medication_plan.dosage_unit_codes.N": "Pipettenteilstrich",
  "medication_plan.dosage_unit_codes.O": "Sprühstoß",
  "medication_plan.dosage_unit_codes.P": "IE",
  "medication_plan.dosage_unit_codes.Q": "cm",
  "medication_plan.dosage_unit_codes.R": "l",
  "medication_plan.dosage_unit_codes.S": "ml",
  "medication_plan.dosage_unit_codes.T": "g",
  "medication_plan.dosage_unit_codes.U": "kg",
  "medication_plan.dosage_unit_codes.V": "mg",
  "medication_plan.dose_forms.AEO": "Aetherisches OEL",
  "medication_plan.dose_forms.AMP": "Ampullen",
  "medication_plan.dose_forms.APA": "Ampullenpaare",
  "medication_plan.dose_forms.ASN": "Augen- und Nasensalbe",
  "medication_plan.dose_forms.ASO": "Augen- und Ohrensalbe",
  "medication_plan.dose_forms.ATO": "Augen- und Ohrentropfen",
  "medication_plan.dose_forms.ATR": "Augentropfen",
  "medication_plan.dose_forms.AUB": "Augenbad",
  "medication_plan.dose_forms.AUC": "Augencreme",
  "medication_plan.dose_forms.AUG": "Augensalbe",
  "medication_plan.dose_forms.BAD": "Bad",
  "medication_plan.dose_forms.BAL": "Balsam",
  "medication_plan.dose_forms.BAN": "Bandage",
  "medication_plan.dose_forms.BEU": "Beutel",
  "medication_plan.dose_forms.BIN": "Binden",
  "medication_plan.dose_forms.BON": "Bonbons",
  "medication_plan.dose_forms.BPL": "Basisplatte",
  "medication_plan.dose_forms.BRE": "Brei",
  "medication_plan.dose_forms.BTA": "Brausetabletten",
  "medication_plan.dose_forms.CRE": "Creme",
  "medication_plan.dose_forms.DFL": "Durchstechflaschen",
  "medication_plan.dose_forms.DIG": "Digitale Gesundheitsanwendungen",
  "medication_plan.dose_forms.DIL": "Dilution",
  "medication_plan.dose_forms.DIS": "Depot-Injektionssuspension",
  "medication_plan.dose_forms.DKA": "Dragees in Kalenderpackung",
  "medication_plan.dose_forms.DOS": "Dosieraerosol",
  "medication_plan.dose_forms.DRA": "Dragees",
  "medication_plan.dose_forms.DRM": "Dragees Magensaftresistent",
  "medication_plan.dose_forms.DSC": "Dosierschaum",
  "medication_plan.dose_forms.DSS": "Dosierspray",
  "medication_plan.dose_forms.EDP": "Einzeldosis-pipetten",
  "medication_plan.dose_forms.EIN": "Einreibung",
  "medication_plan.dose_forms.ELE": "Elektroden",
  "medication_plan.dose_forms.ELI": "Elixier",
  "medication_plan.dose_forms.EMU": "Emulsion",
  "medication_plan.dose_forms.ESS": "Essenz",
  "medication_plan.dose_forms.ESU": "Erwachsenen-suppositorien",
  "medication_plan.dose_forms.EXT": "Extrakt",
  "medication_plan.dose_forms.FBE": "Filterbeutel",
  "medication_plan.dose_forms.FBW": "Franzbranntwein",
  "medication_plan.dose_forms.FDA": "Filmdragees",
  "medication_plan.dose_forms.FER": "Fertigspritzen",
  "medication_plan.dose_forms.FET": "Fettsalbe",
  "medication_plan.dose_forms.FLA": "Flasche",
  "medication_plan.dose_forms.FLE": "Fluessigkeit zum einnehmen",
  "medication_plan.dose_forms.FLU": "Fluessig",
  "medication_plan.dose_forms.FMR": "Filmtabletten magensaftresistent",
  "medication_plan.dose_forms.FOL": "Folie",
  "medication_plan.dose_forms.FRB": "Beutel mit retardierten filmtabletten",
  "medication_plan.dose_forms.FSE": "Fluessigseife",
  "medication_plan.dose_forms.FTA": "Filmtabletten",
  "medication_plan.dose_forms.GEK": "Granulat zur entnahme aus kapseln",
  "medication_plan.dose_forms.GEL": "Gel",
  "medication_plan.dose_forms.GLI": "Gas und Lösungsmittel zur Herst. Injekt./Infusionsdispersion",
  "medication_plan.dose_forms.GLO": "Globuli",
  "medication_plan.dose_forms.GMR": "Magensaftresistentes granulat",
  "medication_plan.dose_forms.GPA": "Gelplatte",
  "medication_plan.dose_forms.GRA": "Granulat",
  "medication_plan.dose_forms.GSE": "Granulat zur herstellung einer suspension zum einnehmen",
  "medication_plan.dose_forms.GUL": "Gurgelloesung",
  "medication_plan.dose_forms.HAS": "Handschuhe",
  "medication_plan.dose_forms.HKM": "Magensaftresistente Hartkapseln",
  "medication_plan.dose_forms.HKP": "Hartkapseln",
  "medication_plan.dose_forms.HPI": "Hartkapseln mit pulver zur inhalation",
  "medication_plan.dose_forms.HVW": "Hartkapseln mit veraenderter wirkstofffreisetzung",
  "medication_plan.dose_forms.IFA": "Infusionsampullen",
  "medication_plan.dose_forms.IFB": "Infusionsbeutel",
  "medication_plan.dose_forms.IFD": "Infusionsdispersion",
  "medication_plan.dose_forms.IFE": "Injektionslösung in einer Fertigspritze",
  "medication_plan.dose_forms.IFF": "Infusionsflaschen",
  "medication_plan.dose_forms.IFK": "Infusionsloesungs-konzentrat",
  "medication_plan.dose_forms.IFL": "Injektionsflaschen",
  "medication_plan.dose_forms.IFS": "Infusionsset",
  "medication_plan.dose_forms.IHA": "Inhalationsampullen",
  "medication_plan.dose_forms.IHP": "Inhalationspulver",
  "medication_plan.dose_forms.IID": "Injektions- und Infusionsdispersion",
  "medication_plan.dose_forms.IIE": "Injektions- oder Infusionslösung oder Lösung zum Einnehmen",
  "medication_plan.dose_forms.IIL": "Injektions-, Infusionslösung",
  "medication_plan.dose_forms.IIM": "Injektionslösung zur Intramuskulären Anwendung",
  "medication_plan.dose_forms.IJD": "Injektionsdispersion",
  "medication_plan.dose_forms.IKA": "Inhalationskapseln",
  "medication_plan.dose_forms.ILO": "Injektionsloesung",
  "medication_plan.dose_forms.IMP": "Implantat",
  "medication_plan.dose_forms.INF": "Infusionsloesung",
  "medication_plan.dose_forms.INH": "Inhalat",
  "medication_plan.dose_forms.INI": "Injektions-, Infusionsflaschen",
  "medication_plan.dose_forms.INL": "Inhalationsloesung",
  "medication_plan.dose_forms.INS": "Instant-tee",
  "medication_plan.dose_forms.IST": "Instillation",
  "medication_plan.dose_forms.ISU": "Injektionssuspension",
  "medication_plan.dose_forms.IUP": "Intrauterinpessar",
  "medication_plan.dose_forms.KAN": "Kanuelen",
  "medication_plan.dose_forms.KAP": "Kapseln",
  "medication_plan.dose_forms.KAT": "Katheter",
  "medication_plan.dose_forms.KDA": "Kaudragees",
  "medication_plan.dose_forms.KEG": "Kegel",
  "medication_plan.dose_forms.KER": "Kerne",
  "medication_plan.dose_forms.KGU": "Kaugummi",
  "medication_plan.dose_forms.KID": "Konzentrat zur Herstellung einer Infusionsdispersion",
  "medication_plan.dose_forms.KII": "Konzentrat zur Herstellung einer Injektions- O.Infusionslsg.",
  "medication_plan.dose_forms.KKS": "Kleinkinder-suppositorien",
  "medication_plan.dose_forms.KLI": "Klistiere",
  "medication_plan.dose_forms.KLT": "Klistier-tabletten",
  "medication_plan.dose_forms.KMP": "Hartkapseln mit magensaftresistent ueberzogenen pellets",
  "medication_plan.dose_forms.KMR": "Kapseln magensaftresistent",
  "medication_plan.dose_forms.KOD": "Kondome",
  "medication_plan.dose_forms.KOM": "Kompressen",
  "medication_plan.dose_forms.KON": "Konzentrat",
  "medication_plan.dose_forms.KPG": "Kombipackung",
  "medication_plan.dose_forms.KRI": "Kristallsuspension",
  "medication_plan.dose_forms.KSS": "Kinder- und Saeuglings-suppositorien",
  "medication_plan.dose_forms.KSU": "Kinder-suppositorien",
  "medication_plan.dose_forms.KTA": "Kautabletten",
  "medication_plan.dose_forms.LAN": "Lanzetten",
  "medication_plan.dose_forms.LII": "Loesung zur Injektion, Infusion und Inhalation",
  "medication_plan.dose_forms.LIQ": "Liquidum",
  "medication_plan.dose_forms.LIV": "Lösung zur intravesikalen Anwendung",
  "medication_plan.dose_forms.LOE": "Loesung",
  "medication_plan.dose_forms.LOT": "Lotion",
  "medication_plan.dose_forms.LOV": "Lösung für einen Vernebler",
  "medication_plan.dose_forms.LSE": "Loesung zum einnehmen",
  "medication_plan.dose_forms.LTA": "Lacktabletten",
  "medication_plan.dose_forms.LUP": "Lutschpastillen",
  "medication_plan.dose_forms.LUT": "Lutschtabletten",
  "medication_plan.dose_forms.LYO": "Lyophilisat zur Herstellung einer Infusionslösung",
  "medication_plan.dose_forms.MIL": "Milch",
  "medication_plan.dose_forms.MIS": "Mischung",
  "medication_plan.dose_forms.MIX": "Mixtur",
  "medication_plan.dose_forms.MRG": "Magensaftresistentes retardgranulat",
  "medication_plan.dose_forms.MRP": "Magensaftresistente pellets",
  "medication_plan.dose_forms.MTA": "Manteltabletten",
  "medication_plan.dose_forms.MUW": "Mundwasser",
  "medication_plan.dose_forms.NAG": "Nasengel",
  "medication_plan.dose_forms.NAO": "Nasenoel",
  "medication_plan.dose_forms.NAS": "Nasenspray",
  "medication_plan.dose_forms.NAW": "Wirkstoffhaltiger nagellack",
  "medication_plan.dose_forms.NDS": "Nasendosierspray",
  "medication_plan.dose_forms.NSA": "Nasensalbe",
  "medication_plan.dose_forms.NTR": "Nasentropfen",
  "medication_plan.dose_forms.OCU": "Ocusert",
  "medication_plan.dose_forms.OEL": "Oel",
  "medication_plan.dose_forms.OHT": "Ohrentropfen",
  "medication_plan.dose_forms.OVU": "Ovula",
  "medication_plan.dose_forms.PAM": "Packungsmasse",
  "medication_plan.dose_forms.PAS": "Pastillen",
  "medication_plan.dose_forms.PEL": "Pellets",
  "medication_plan.dose_forms.PEN": "Injektionsloesung in einem fertigpen",
  "medication_plan.dose_forms.PER": "Perlen",
  "medication_plan.dose_forms.PFL": "Pflaster",
  "medication_plan.dose_forms.PFT": "Pflaster transdermal",
  "medication_plan.dose_forms.PHI": "Pulver Z Herst E Inj-, Inf- O Inhal LOE",
  "medication_plan.dose_forms.PHV": "Plv.Herst.Injekt/Infus Oder Plv+LM Herst.LSG.Intravesik.ANW.",
  "medication_plan.dose_forms.PIE": "Pulver für Konz.z.Herst.Infus.Lsg,Pulver z.Herst.Lsg z.Einn.",
  "medication_plan.dose_forms.PIF": "Pulver Zur Herstellung einer Infusionsloesung",
  "medication_plan.dose_forms.PII": "Pulver Zur Herstellung einer Injektions- oder Infusionsloesu",
  "medication_plan.dose_forms.PIJ": "Pulver Zur Herstellung einer Injektionsloesung",
  "medication_plan.dose_forms.PIK": "Pulver Zur Herstellung eines Infusionsloesungskonzentrates",
  "medication_plan.dose_forms.PIS": "Pulver Zur Herstellung einer Infusionssuspension",
  "medication_plan.dose_forms.PIV": "Pulver Z.Herst.Injekt.-/Infusionslsg.O.LSG.Intravesikal.ANW.",
  "medication_plan.dose_forms.PKI": "Pulver für ein Konzentrat zur Herstellung einer Infusionslsg",
  "medication_plan.dose_forms.PLD": "Pulver und Lösungsmittel zur Herst.einer Depot-Injektionssus",
  "medication_plan.dose_forms.PLE": "Pulver zur Herstellung einer Loesung zum einnehmen",
  "medication_plan.dose_forms.PLF": "Pulver U Loe-M Z Herst E Inf-Loe",
  "medication_plan.dose_forms.PLG": "Perlongetten",
  "medication_plan.dose_forms.PLH": "Pulver U Loe-M Z Herst E Inj-/Inf-Loe",
  "medication_plan.dose_forms.PLI": "Pulver und Loesungsmittel zur Herstellung einer Injektionslo",
  "medication_plan.dose_forms.PLK": "Pulver U Loe-M F Konz Z Herstellung einer infusionsloesung",
  "medication_plan.dose_forms.PLS": "Pulver und Lösungsmittel zur Herst. einer Injektionssuspens.",
  "medication_plan.dose_forms.PLV": "Pulver u.Lösungsm.z.Herst.einer Lösung z.Intravesikalen Anw.",
  "medication_plan.dose_forms.PPL": "Pumploesung",
  "medication_plan.dose_forms.PRS": "Presslinge",
  "medication_plan.dose_forms.PSE": "Pulver zur Herstellung einer suspension zum einnehmen",
  "medication_plan.dose_forms.PST": "Paste",
  "medication_plan.dose_forms.PUD": "Puder",
  "medication_plan.dose_forms.PUL": "Pulver",
  "medication_plan.dose_forms.RED": "Retard-dragees",
  "medication_plan.dose_forms.REK": "Retard-kapseln",
  "medication_plan.dose_forms.RET": "Retard-tabletten",
  "medication_plan.dose_forms.RGR": "Retard-granulat",
  "medication_plan.dose_forms.RKA": "Rektalkapseln",
  "medication_plan.dose_forms.RMS": "Retardmikrokapseln und suspensionsmittel",
  "medication_plan.dose_forms.RSC": "Rektalschaum",
  "medication_plan.dose_forms.RSU": "Rektalsuspension",
  "medication_plan.dose_forms.RUT": "Retard-ueberzogene tabletten",
  "medication_plan.dose_forms.SAF": "Saft",
  "medication_plan.dose_forms.SAL": "Salbe",
  "medication_plan.dose_forms.SAM": "Salbe zur anwendung in der mundhoehle",
  "medication_plan.dose_forms.SCH": "Schaum",
  "medication_plan.dose_forms.SEI": "Seife",
  "medication_plan.dose_forms.SHA": "Shampoo",
  "medication_plan.dose_forms.SIR": "Sirup",
  "medication_plan.dose_forms.SLZ": "Salz",
  "medication_plan.dose_forms.SMF": "Schmelzfilm",
  "medication_plan.dose_forms.SMT": "Schmelztabletten",
  "medication_plan.dose_forms.SMU": "Suppositorien mit mulleinlage",
  "medication_plan.dose_forms.SPA": "Spritzampullen",
  "medication_plan.dose_forms.SPF": "Spruehflasche",
  "medication_plan.dose_forms.SPL": "Spuelloesung",
  "medication_plan.dose_forms.SPR": "Spray",
  "medication_plan.dose_forms.SPT": "Transdermales spray",
  "medication_plan.dose_forms.SRI": "Spritzen",
  "medication_plan.dose_forms.SSU": "Saeuglings-suppositorien",
  "medication_plan.dose_forms.STA": "Stechampullen",
  "medication_plan.dose_forms.STB": "Staebchen",
  "medication_plan.dose_forms.STI": "Stifte",
  "medication_plan.dose_forms.STR": "Streifen",
  "medication_plan.dose_forms.SUB": "Substanz",
  "medication_plan.dose_forms.SUE": "Suspension zum einnehmen",
  "medication_plan.dose_forms.SUI": "Suspension zur implantation",
  "medication_plan.dose_forms.SUL": "Sublingualspray, Lösung",
  "medication_plan.dose_forms.SUP": "Suppositorien",
  "medication_plan.dose_forms.SUS": "Suspension",
  "medication_plan.dose_forms.SUT": "Sublingualtabletten",
  "medication_plan.dose_forms.SUV": "Suspension für einen Vernebler",
  "medication_plan.dose_forms.SWA": "Schwaemme",
  "medication_plan.dose_forms.TAB": "Tabletten",
  "medication_plan.dose_forms.TAE": "Taefelchen",
  "medication_plan.dose_forms.TAM": "Trockenampullen",
  "medication_plan.dose_forms.TEE": "Tee",
  "medication_plan.dose_forms.TEI": "Tropfen zum einnehmen",
  "medication_plan.dose_forms.TES": "Test",
  "medication_plan.dose_forms.TIN": "Tinktur",
  "medication_plan.dose_forms.TKA": "Tabletten in kalenderpackung",
  "medication_plan.dose_forms.TLE": "Tablette zur Herstellung einer Lösung zum Einnehmen",
  "medication_plan.dose_forms.TMR": "Tabletten magensaftresistent",
  "medication_plan.dose_forms.TON": "Tonikum",
  "medication_plan.dose_forms.TPN": "Tampon",
  "medication_plan.dose_forms.TPO": "Tamponaden",
  "medication_plan.dose_forms.TRA": "Trinkampullen",
  "medication_plan.dose_forms.TRI": "Trituration",
  "medication_plan.dose_forms.TRO": "Tropfen",
  "medication_plan.dose_forms.TRS": "Trockensubstanz mit loesungsmittel",
  "medication_plan.dose_forms.TRT": "Trinktabletten",
  "medication_plan.dose_forms.TSA": "Trockensaft",
  "medication_plan.dose_forms.TSD": "Tabletten zur Herst.einer Suspens.zum Einn.für Dosierspender",
  "medication_plan.dose_forms.TSE": "Tablette zur Herstellung Einer Suspension zum einnehmen",
  "medication_plan.dose_forms.TSS": "Trockensubstanz ohne Loesungsmittel",
  "medication_plan.dose_forms.TST": "Teststaebchen",
  "medication_plan.dose_forms.TSY": "Transdermales system",
  "medication_plan.dose_forms.TTR": "Teststreifen",
  "medication_plan.dose_forms.TUB": "Tube",
  "medication_plan.dose_forms.TUE": "Tuecher",
  "medication_plan.dose_forms.TUP": "Tupfer",
  "medication_plan.dose_forms.TVW": "Tablette mit veränderter Wirkstofffreisetzung",
  "medication_plan.dose_forms.UTA": "Ueberzogene tabletten",
  "medication_plan.dose_forms.VAL": "Vaginalloesung",
  "medication_plan.dose_forms.VAR": "Vaginalring",
  "medication_plan.dose_forms.VCR": "Vaginalcreme",
  "medication_plan.dose_forms.VER": "Verband",
  "medication_plan.dose_forms.VGE": "Vaginalgel",
  "medication_plan.dose_forms.VKA": "Vaginalkapseln",
  "medication_plan.dose_forms.VLI": "Vlies",
  "medication_plan.dose_forms.VOV": "Vaginalovula",
  "medication_plan.dose_forms.VST": "Vaginalstaebchen",
  "medication_plan.dose_forms.VSU": "Vaginalsuppositorien",
  "medication_plan.dose_forms.VTA": "Vaginaltabletten",
  "medication_plan.dose_forms.WAT": "Watte",
  "medication_plan.dose_forms.WGA": "Wundgaze",
  "medication_plan.dose_forms.WKA": "Weichkapseln",
  "medication_plan.dose_forms.WKM": "Magensaftresistente weichkapseln",
  "medication_plan.dose_forms.WUE": "Wuerfel",
  "medication_plan.dose_forms.XDG": "Duschgel",
  "medication_plan.dose_forms.XDS": "Deo-spray",
  "medication_plan.dose_forms.XFE": "Festiger",
  "medication_plan.dose_forms.XGM": "Gesichtsmaske",
  "medication_plan.dose_forms.XHA": "Halsband",
  "medication_plan.dose_forms.XHS": "Haarspuelung",
  "medication_plan.dose_forms.XNC": "Nachtcreme",
  "medication_plan.dose_forms.XPK": "Koerperpflege",
  "medication_plan.dose_forms.XTC": "Tagescreme",
  "medication_plan.dose_forms.ZAM": "Zylinderampullen",
  "medication_plan.dose_forms.ZBU": "Zahnbuerste",
  "medication_plan.dose_forms.ZCR": "Zahncreme",
  "medication_plan.dose_forms.ZGE": "Zahngel",
  "medication_plan.dose_forms.ZKA": "Zerbeisskapseln",
  "medication_plan.dose_forms.ZPA": "Zahnpasta",
  "medication_plan.error_message": "Die Medikationsplan-Datei ist ungültig.",
  "medication_plan.labels.active_ingredient": "Wirkstoff",
  "medication_plan.labels.allergies_intolerances": "Allerg./Unv.",
  "medication_plan.labels.breastfeeding": "Stillend",
  "medication_plan.labels.cms": "cm",
  "medication_plan.labels.commercial_name": "Handelsname",
  "medication_plan.labels.creatinine": "Krea.",
  "medication_plan.labels.dosage_evening": "abends",
  "medication_plan.labels.dosage_free_text": "Dosierung",
  "medication_plan.labels.dosage_morning": "morgens",
  "medication_plan.labels.dosage_night": "zur Nacht",
  "medication_plan.labels.dosage_noon": "mittags",
  "medication_plan.labels.dosage_unit": "Einheit",
  "medication_plan.labels.dose_form": "Form",
  "medication_plan.labels.email": "E-Mail",
  "medication_plan.labels.gender": "Geschl.",
  "medication_plan.labels.height": "Größe",
  "medication_plan.labels.instructions": "Hinweise",
  "medication_plan.labels.insurance_number": "Versicherungsnummer",
  "medication_plan.labels.kgs": "kg",
  "medication_plan.labels.mg_dl": "mg/dl",
  "medication_plan.labels.patient_birthdate": "Geburtsdatum des Patienten",
  "medication_plan.labels.phone": "Tel",
  "medication_plan.labels.pregnancy": "Schwanger",
  "medication_plan.labels.printed_by": "Gedruckt von",
  "medication_plan.labels.printed_on": "Gedruckt am",
  "medication_plan.labels.reason": "Grund",
  "medication_plan.labels.strength": "Stärke",
  "medication_plan.labels.weight": "Gewicht",
  "medication_plan.loader_error": "Beim Laden der Medikationsplandaten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  "medication_plan.print": "Drucken",
  "medication_plan.section_title_codes.allergy_information": "Allergiehinweise",
  "medication_plan.section_title_codes.application_under_skin": "Anwendung unter die Haut",
  "medication_plan.section_title_codes.important_information": "Wichtige Angaben",
  "medication_plan.section_title_codes.important_notes": "Wichtige Hinweise",
  "medication_plan.section_title_codes.intramuscular_application": "Intramuskuläre Anwendung",
  "medication_plan.section_title_codes.intravenous_application": "Intravenöse Anwendung",
  "medication_plan.section_title_codes.limited_time_medication": "zeitlich befristet anzuwendende Medikamente",
  "medication_plan.section_title_codes.long_term_medication": "Dauermedikation",
  "medication_plan.section_title_codes.medication_at_special_times": "zu besonderen Zeiten anzuwendende Medikamente",
  "medication_plan.section_title_codes.on_demand_medication": "Bedarfsmedikation",
  "medication_plan.section_title_codes.prefill_syringe": "Fertigspritze",
  "medication_plan.section_title_codes.self_medication": "Selbstmedikation",
  "medication_plan.section_title_codes.special_application": "Besondere Anwendung",
  "medication_plan.title": "Medikationsplan",
  "medication_plan.warning_message": "Das Dokument enthält Formatfehler und ist kein standardkonformer Bundeseinheitlicher Medikationsplan. Er könnte Anweisungen enthalten, die wir nicht anzeigen. Bitte vergleichen Sie mit dem originalen Ausdruck und folgen Sie diesem.",
  "patient_forms.errors.invalid": "Eins oder mehrere Formularfelder fehlen oder haben das falsche Format. Bitte korrigieren Sie die rot-markierten Felder. Sie können das Formular auch als Entwurf speichern, müssen es aber absenden damit Ihre Praxis darauf zugreifen kann.",
  "patient_forms.errors.loading": "Beim Laden von existierenden Fomularwerten ist es zu einem Fehler gekommen. Sie können das Fomular neu ausfüllen, oder den Benutzerdienst kontaktieren.",
  "patient_forms.errors.submitting": "Speichern fehlgeschlagen. Bitte überprüfen Sie ihre Internetverbindung und versuchen Sie es zu einem späteren Zeitpunkt erneut.",
  "tenant_selector.visited_title": "Meine Patientenportale"
}
