import { Controller } from '@hotwired/stimulus'

/*
 * Connects to data-controller="medication-plan--accordion-toggle"
 * This controller is used to expand all items in an accordion
 * implemented with the checkbox label toggle trick https://css-tricks.com/the-checkbox-hack/
 *
 * checkboxToggle: The checkboxes that toggle each of the items
 */
export default class extends Controller {
  static targets = ['checkboxToggle', 'toggleAllIconOpen', 'toggleAllIconClose']

  declare checkboxToggleTargets: HTMLInputElement[]
  declare toggleAllIconOpenTarget: HTMLElement
  declare toggleAllIconCloseTarget: HTMLElement

  toggleOne() {
    this.updateAccordionToggle(this.isAnyExpanded())
  }

  toggleAll() {
    const expand = !this.isAnyExpanded()
    this.expandAll(expand)
    this.updateAccordionToggle(expand)
  }

  isAnyExpanded() {
    return this.checkboxToggleTargets.some((checkbox) => checkbox.checked)
  }

  expandAll(expand: boolean) {
    this.checkboxToggleTargets.map((checkbox) => (checkbox.checked = expand))
  }

  updateAccordionToggle(expanded: boolean) {
    if (expanded) {
      this.showCloseToggle()
    } else {
      this.showOpenToggle()
    }
  }

  showOpenToggle() {
    this.toggleAllIconOpenTarget.classList.remove('hidden')
    this.toggleAllIconCloseTarget.classList.add('hidden')
  }

  showCloseToggle() {
    this.toggleAllIconOpenTarget.classList.add('hidden')
    this.toggleAllIconCloseTarget.classList.remove('hidden')
  }
}
