import { Controller } from '@hotwired/stimulus'

import { assignPharmaProductInfo } from 'services/medicationPlan/assignPharmaProductInfo'

import createBMPStructure from '../../services/medicationPlan/createBMPStructure'
import {
  buildMedicationPlanHTML,
  warningMessage,
  errorMessage,
} from '../../services/medicationPlan/generateHtml'
import parseMedicationPlan from '../../services/medicationPlan/parseMedicationPlan'
import { BMP } from '../../types/medicationPlan'

interface BMPLoadedEvent extends Event {
  detail: string
}

function runAfterRender(callback: () => void) {
  // Ensures callback runs after the DOM is updated
  requestAnimationFrame(callback)
}
/**
 * MedicationPlanShow renders the medication plan as HTML
 */
// Connects to data-controller="medication-plan--show"
export default class extends Controller<HTMLDivElement> {
  static targets = ['medicationPlanContainer']
  static values = { bmp: String }

  connect() {
    if (this.hasBmpValue) {
      this.renderHtml(this.bmpValue)
    }
  }

  renderBmpObject(event: BMPLoadedEvent) {
    this.renderHtml(event.detail)
  }

  async renderPharmaProductInfo(bmp: string, bmpDocument: BMP) {
    await assignPharmaProductInfo(bmpDocument)
    this.medicationPlanContainerTarget.replaceChildren(buildMedicationPlanHTML(bmpDocument, bmp))
  }

  async renderHtml(bmp: string) {
    try {
      const parsedMedicationPlan = parseMedicationPlan(bmp)
      const bmpDocument = createBMPStructure(parsedMedicationPlan?.document)

      if (parsedMedicationPlan.validation.warnings.length > 0) {
        this.element.prepend(warningMessage())
      }

      // Render generated HTML of the medication plan
      this.medicationPlanContainerTarget.replaceChildren(buildMedicationPlanHTML(bmpDocument, bmp))

      // Schedule the post-render method to fetch and add pharma product information after the initial render
      // Doing it like this speeds up the initial render
      runAfterRender(() => {
        this.renderPharmaProductInfo(bmp, bmpDocument)
      })
    } catch (e) {
      this.element.replaceChildren(errorMessage())
      console.error(e)
    }
  }

  print() {
    window.print()
  }

  declare hasBmpValue: boolean
  declare bmpValue: string
  declare medicationPlanContainerTarget: HTMLDivElement
}
