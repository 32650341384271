import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tenant']

  search(event: InputEvent) {
    const { value } = event.target as HTMLInputElement

    this.tenantTargets.forEach((tenant) => {
      const name = tenant.dataset.searchTenantsName?.toLocaleLowerCase() || ''
      const inputValue = value.toLocaleLowerCase()

      // Special handling for tenants with HTTP Auth - not ready to be public
      if (tenant.dataset.hideOnMobile === 'true') {
        if (name === inputValue) {
          tenant.classList.remove('hidden')
        } else {
          tenant.classList.add('hidden')
        }
      } else {
        if (name.includes(inputValue)) {
          tenant.classList.remove('hidden')
        } else {
          tenant.classList.add('hidden')
        }
      }
    })
  }

  declare readonly tenantTargets: HTMLElement[]
}
