import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

import {
  getMasterKeys,
  generateAuthKeyFromPassword,
  hashPassword,
  loadDeviceKeyPairForRecoveryKey,
} from '../encryption/encryption'
import {
  exportDeviceKeyPrivateKey,
  exportDeviceKeyPublicKey,
  exportPatientUserMasterKey,
  generateDeviceKeyPair,
} from '../encryption/keyHandling'

// Connects to data-controller="reset-password"
export default class extends Controller<HTMLFormElement> {
  async validateRecoveryKey(event: CustomEvent) {
    // The event is also triggered for normal links within the form.
    // We only want to run the code below if the form is actually being submitted.
    if (event.detail.fetchOptions.method === 'GET') {
      return
    }

    event.preventDefault()
    const recoveryKey = this.element.patient_user_recovery_key.value
    const resetPasswordToken = this.element.patient_user_reset_password_token.value
    window.sessionStorage.setItem('reset_password_token', resetPasswordToken)

    if (await loadDeviceKeyPairForRecoveryKey(recoveryKey)) {
      for (const [key, value] of Object.entries(await this.requestParams())) {
        event.detail.fetchOptions.body.set(key, value)
      }

      // ######################################################
      // # This feature is needed for C5 compliance for PSS-07
      // ######################################################
      await this.hashPasswords(event)
      event.detail.resume()
    } else {
      Turbo.visit(
        `/patient_users/password/edit?reset_password_token=${resetPasswordToken}&recovery_key_error=true`,
        { action: 'replace' },
      )
    }
  }

  // This function is called by another controller's action which is async (we need to wait for it to be done)
  async procceedWithoutRecoveryKey({ detail: { event } }: { detail: { event: CustomEvent } }) {
    event.preventDefault()
    const resetPasswordToken = this.element.patient_user_reset_password_token.value
    window.sessionStorage.setItem('reset_password_token', resetPasswordToken)

    // ######################################################
    // # This feature is needed for C5 compliance for PSS-07
    // ######################################################
    await this.hashPasswords(event)

    event.detail.resume()
  }

  async cleanStorage(event: CustomEvent) {
    if (event.detail.fetchResponse.succeeded) {
      window.sessionStorage.removeItem('reset_password_token')
      window.sessionStorage.removeItem('deviceKey')
    }
  }

  async encryptedMasterKeyData(publicKey: CryptoKey) {
    const masterKeys = await getMasterKeys()
    const masterKeyData = await Promise.all(
      masterKeys.map(async (key) => {
        return {
          id: key.id,
          data_encrypted: await exportPatientUserMasterKey(publicKey, key.key),
        }
      }),
    )
    return masterKeyData
  }

  async requestParams() {
    const baseKey = await generateAuthKeyFromPassword(this.element.patient_user_password.value)
    const deviceKeyPair = await generateDeviceKeyPair()
    const exportedPrivateKey = await exportDeviceKeyPrivateKey(
      baseKey.key,
      deviceKeyPair.privateKey,
    )
    const exportedPublicKey = await exportDeviceKeyPublicKey(deviceKeyPair.publicKey)

    return {
      public_key: JSON.stringify(exportedPublicKey),
      private_key: exportedPrivateKey,
      pbkdf_salt: baseKey.pbkdfSalt,
      pbkdf_iterations: baseKey.pbkdfIterations,
      master_key_data: JSON.stringify(await this.encryptedMasterKeyData(deviceKeyPair.publicKey)),
    }
  }

  async hashPasswords(event: CustomEvent) {
    const hashedPassword = await hashPassword(
      this.element.patient_user_email.value,
      this.element.patient_user_password.value,
    )
    const hashedPasswordConfirmation = await hashPassword(
      this.element.patient_user_email.value,
      this.element.patient_user_password_confirmation.value,
    )

    event.detail.fetchOptions.body.set('patient_user[password]', hashedPassword)
    event.detail.fetchOptions.body.set(
      'patient_user[password_confirmation]',
      hashedPasswordConfirmation,
    )
  }
}
