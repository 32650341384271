import { BridgeComponent } from '@hotwired/hotwire-native-bridge'

interface VersionResult {
  appName: string
  appVersion: string
  appBuild: string
}

/**
 * This controller is used to display the app version in the footer.
 *
 * When the user taps the element 8 times, it will signal the bridge to
 * change the environment.
 */
export default class BridgeAppVersionController extends BridgeComponent<HTMLFormElement> {
  static component = 'bridge--app-version'

  static targets = ['appName', 'appVersion', 'appBuild']

  private clickCount = 0

  connect() {
    super.connect()
    this.send<{}, { data: VersionResult }>('get-version', {}, ({ data }) => {
      if (this.hasAppNameTarget) {
        this.appNameTarget.textContent = data.appName
      }

      if (this.hasAppVersionTarget) {
        this.appVersionTarget.textContent = data.appVersion
      }

      if (this.hasAppBuildTarget) {
        this.appBuildTarget.textContent = data.appBuild
      }

      this.element.classList.remove('hidden')
    })
  }

  // When the user clicks the app name 8 times in a row, they can change the app environment
  async click() {
    this.clickCount++

    if (this.clickCount === 8) {
      this.clickCount = 0
      this.send('change-environment')
    }
  }

  declare appNameTarget: HTMLElement
  declare appVersionTarget: HTMLElement
  declare appBuildTarget: HTMLElement
  declare hasAppNameTarget: boolean
  declare hasAppVersionTarget: boolean
  declare hasAppBuildTarget: boolean
}
