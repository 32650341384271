import { BridgeComponent } from '@hotwired/hotwire-native-bridge'

/**
 * Native Bridge Controller for elements in the tenant selector.
 *
 * When selecting a new tenant, we need to send `tenant-selected`
 * to the native bridge and then navigate to the new tenant's
 * dashboard. It contains tenant details for displaying later.
 */
export default class extends BridgeComponent<HTMLAnchorElement> {
  static component = 'bridge--pick-tenant'
  static values = { tenantId: String, tenantDomain: String, tenantName: String, tenantImg: String }

  selected(event: Event) {
    event.preventDefault()

    this.send('tenant-selected', {
      tenantId: this.tenantIdValue,
      tenantDomain: this.tenantDomainValue,
      tenantName: this.tenantNameValue,
      tenantImg: this.tenantImgValue,
    })
  }

  declare tenantIdValue: string
  declare tenantDomainValue: string
  declare tenantNameValue: string
  declare tenantImgValue: string

  declare recentlyVisitedTarget: HTMLElement
}
