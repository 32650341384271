import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

import {
  generateAndUploadDeviceKeyPairForPassword,
  isDeviceKeyLoaded,
  loadDeviceKeyPairForPassword,
  loadDeviceKeyFromSession,
} from '../encryption/encryption'

/**
 * EncryptionController allow the creation and set of the encryption keys
 */
export default class EncryptionController extends Controller<HTMLFormElement> {
  static values = { needsRecoveryToken: Boolean, redirectPath: String }

  async unlock(event: CustomEvent) {
    event.detail.formSubmission.stop()
    await this.unlockOrSetPassword(event, loadDeviceKeyPairForPassword)
  }

  async setPassword(event: Event) {
    this.unlockOrSetPassword(event, generateAndUploadDeviceKeyPairForPassword)
  }

  async unlockOrSetPassword(event: Event, func: Function) {
    event.stopPropagation()
    event.preventDefault()

    const form = this.element
    const password = form.password.value
    const success = await func(password)
    if (success) {
      let successUrl

      if (this.needsRecoveryTokenValue) {
        successUrl = '/recovery_tokens'
      } else {
        successUrl = new URL(
          this.redirectPathValue || window.location.origin,
          window.location.origin,
        )
        // We need to use `append()` because there can be other searchParams already as part
        // of the URL, e.g. when continuing a booking.
        successUrl.searchParams.append('encryption_unlocked', 'true')
      }

      Turbo.visit(successUrl.toString(), { action: 'replace' })
    } else {
      Turbo.visit('/encryption/unlock?invalid_password=true', { action: 'replace' })
    }

    this.dispatch('encryptionUnlocked')
  }

  async connect() {
    await loadDeviceKeyFromSession()

    if (isDeviceKeyLoaded()) {
      Turbo.visit('/?encryption_unlocked=true', { action: 'replace' })
    }
  }

  declare needsRecoveryTokenValue: boolean
  declare redirectPathValue: string
}
