import { BridgeComponent } from '@hotwired/hotwire-native-bridge'

type Credentials = { email: string; password: string }

type Result = {
  data: Credentials
}

type Data = Record<string, unknown>

export default class extends BridgeComponent<HTMLAnchorElement> {
  static component = 'bridge--user-credentials-manager'
  static targets = ['email', 'password', 'submitButton']

  setCredentials(event: CustomEvent<Credentials>) {
    event.preventDefault()
    const { email, password } = event.detail

    this.send('set-credentials', {
      email,
      password,
    })
  }

  deleteCredentials = () => {
    this.send('delete-credentials')
  }

  connect() {
    super.connect()
    this.send<Data, Result>('get-credentials', {}, ({ data: { email, password } }) => {
      this.emailTarget.value = email
      this.passwordTarget.value = password

      this.submitButtonTarget.click()
    })
  }

  declare readonly emailTarget: HTMLInputElement
  declare readonly passwordTarget: HTMLInputElement
  declare readonly submitButtonTarget: HTMLButtonElement
}
