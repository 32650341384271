import { Controller } from '@hotwired/stimulus'

import { hashPassword } from '../encryption/encryption'

/**
 * LoginController
 */
export default class PasswordHasingController extends Controller<HTMLFormElement> {
  static targets = ['email', 'password']

  async hashPassword(event: CustomEvent) {
    // The event is also triggered for normal links within the form.
    // We only want to run the code below if the form is actually being submitted.
    if (event.detail.fetchOptions.method === 'GET') {
      return
    }

    event.preventDefault()

    const hashedPassword = await hashPassword(this.emailTarget.value, this.passwordTarget.value)
    event.detail.fetchOptions.body.set('patient_user[password]', hashedPassword)

    event.detail.resume()
  }

  declare passwordTarget: HTMLInputElement
  declare emailTarget: HTMLInputElement
}
