import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

import {
  loadDeviceKeyPairForPassword,
  generateAndUploadDeviceKeyPairForPassword,
} from '../encryption/encryption'

// This is a block-local variable and not accessible from outside the file
let attemptedPassword: string | undefined
let attemptedEmail: string | undefined

/**
 * LoginController
 *
 * Responsible for loading the device key after a successful login.
 *
 * Handling second factor authentication:
 * If the login requires a second factor (or the password is incorrect), the request fails.
 * In this case, the password is stored in a block-local variable and used in the next attempt.
 * For this, the second factor login form must also use `LoginController` and have a `password` target.
 *
 * The password attempt from the initial login is then used for unlocking the device key.
 *
 * If the password was incorrect, it is treated the same way, as we have no way of differentiating
 * between an incorrect password and a second factor requirement. In any case, an incorrect password
 * is of no use.
 */
export default class LoginController extends Controller<HTMLFormElement> {
  static targets = ['email', 'password']

  async onSubmitEnd(event: CustomEvent) {
    if (event.detail.fetchResponse.failed) this.dispatch('onAuthenticationError')

    if (event.detail.fetchResponse.response.url.includes('/encryption/unlocking')) {
      event.preventDefault()
      const password = this.hasPasswordTarget ? this.passwordTarget?.value : attemptedPassword
      const email = this.hasEmailTarget ? this.emailTarget?.value : attemptedEmail
      this.dispatch('onAuthenticationComplete', {
        detail: { email, password },
      })

      if (!password || password.length === 0) {
        return
      }
      try {
        const deviceKey = await loadDeviceKeyPairForPassword(password)
        if (!deviceKey) {
          await generateAndUploadDeviceKeyPairForPassword(password)
        }
      } catch (e) {
        Turbo.visit('/encryption/unlocking_error', { action: 'replace' })
      }

      // Remove the stored password
      attemptedPassword = undefined

      return this.loadDeviceKey(password)
    } else {
      // This might either be an incorrect password or the account requires a second factor.
      // In any case, we store the password in a global variable so that we can load the device key later.
      // If the password is incorrect, it will be overwritten in the next attempt (it's incorrect anyway). No harm done.
      if (this.hasPasswordTarget) attemptedPassword = this.passwordTarget?.value
      if (this.hasEmailTarget) attemptedEmail = this.emailTarget?.value
    }
  }

  async loadDeviceKey(password: string) {
    const deviceKey = await loadDeviceKeyPairForPassword(password)
    if (!deviceKey) {
      await generateAndUploadDeviceKeyPairForPassword(password)
    }
  }

  connect() {
    window.sessionStorage.removeItem('deviceKey')
    window.sessionStorage.removeItem('reset_password_token')
  }

  declare passwordTarget: HTMLInputElement | null
  declare emailTarget: HTMLInputElement | null
  declare hasPasswordTarget: boolean
  declare hasEmailTarget: boolean
}
