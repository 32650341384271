import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

// Connects to data-controller="locale"
export default class extends Controller {
  switch(event: Event) {
    if (event.target instanceof HTMLSelectElement) {
      const newLocale = event.target?.value

      // opt out of page caching so that new page (not the cached one) is rendered on mobile App
      Turbo.cache.exemptPageFromCache()
      Turbo.visit(`/locales/${newLocale}`, { action: 'replace' })
    }
  }
}
