import { Controller } from '@hotwired/stimulus'

import { AESEncryptionKey, loadDeviceKeyFromSessionOnce } from '../encryption/encryption'
import { FileData, decryptFileMetadata } from '../services/downloadFiles'

import { MEDICATION_PLAN_KDL_CODE } from '../shared_constants'

export type DownloadFileEventDetails = {
  fileId: string
  fileName: string
  fileSize: number
  encryptionKey: AESEncryptionKey
}

/**
 * FileDetailsController decrypts a file's metadata and displays it in the target elements
 *
 * It also works together with `download-file` (for web) or `bridge--download-file` (for Hotwire Native App) controllers to download a file
 * In order to make this work, the `file-details` controller dispatches a CustomEvent `download` when the `download` action is called.
 *
 * The CustomEvent.details has all the information needed to download the file (fileId, fileName, fileSize, encryptionKey) and should be
 * listened to by the `download-file` or `bridge--download-file` controllers:
 *
 * <div data-controller="file-details download-file bridge--download-file"
 *      data-file-details-file-id-value="123"
 *      data-file-details-encryption-key-data-value="123"
 *      data-file-details-encryption-key-key-pair-id-value="123"
 *      data-file-details-encrypted-metadata-value="123"
 *      data-file-details-encrypted-name-value="123"
 *      data-action="file-details:download->download-file#download file-details:download->bridge--download-file#download">
 *   ...
 *   <button data-action="file-details#download">Download</button>
 * </div>
 *
 * This way, we do not need to create all the values attributes three times
 */
export default class FileDetailsController extends Controller<HTMLFormElement> {
  static values = {
    fileId: String,
    encryptionKeyData: String,
    encryptionKeyKeyPairId: String,
    encryptedMetadata: String,
    encryptedName: String,
  }

  static targets = ['fileName', 'title', 'showMedicationPlan']
  file?: FileData

  async connect() {
    await loadDeviceKeyFromSessionOnce()

    await this.decryptFileData()

    if (!this.file) {
      return
    }

    for (const target of this.fileNameTargets) {
      if (target.textContent?.trim() === '') {
        target.textContent = this.fileName
      }
    }

    if (this.hasTitleTarget && this.titleTarget.textContent?.trim() === '') {
      const title = this.fileDescription
      this.titleTarget.textContent = title
    }
  }

  async download(event: Event) {
    event.stopPropagation()
    event.preventDefault()

    if (!this.file) {
      throw new Error('No file')
    }

    const details: DownloadFileEventDetails = {
      fileId: this.fileIdValue,
      fileName: this.fileName,
      fileSize: this.file.metaData.size,
      encryptionKey: this.file.encryptionKey,
    }

    // Downloading is handled by `download-file` or `bridge--download-file` controllers
    this.dispatch('download', { detail: details })
  }

  private async decryptFileData() {
    const data = await decryptFileMetadata(
      {
        patientKeyPairId: this.encryptionKeyKeyPairIdValue,
        encryptedData: this.encryptionKeyDataValue,
      },
      this.encryptedMetadataValue,
      this.encryptedNameValue,
    )

    this.detectMedicationPlan(data)

    this.file = {
      id: this.fileIdValue,
      ...data,
    }
  }

  detectMedicationPlan(data: Omit<FileData, 'id'>) {
    if (!this.hasShowMedicationPlanTarget) return

    if (data.metaData.kdlCode === MEDICATION_PLAN_KDL_CODE) {
      this.showMedicationPlanTarget.classList.remove('hidden')
    }
  }

  get fileDescription(): string {
    if (!this.file) {
      return ''
    }

    return this.file.metaData.fileDescription ? this.file.metaData.fileDescription : this.fileName
  }

  get fileName(): string {
    if (!this.file) {
      return ''
    }

    return this.file.fileName
  }

  declare fileIdValue: string
  declare encryptionKeyDataValue: string
  declare encryptionKeyKeyPairIdValue: string
  declare encryptedMetadataValue: string
  declare encryptedNameValue: string

  declare fileNameTargets: [HTMLElement]
  declare titleTarget: HTMLElement
  declare showMedicationPlanTarget: HTMLElement
  declare hasTitleTarget: Boolean
  declare hasShowMedicationPlanTarget: Boolean
}
