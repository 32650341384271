import { BridgeComponent } from '@hotwired/hotwire-native-bridge'

import { CSRFToken } from '../../encryption/encryption'

interface CallbackData {
  data: {
    devicePlatform: string
    registrationId: string
  }
}

export default class extends BridgeComponent<HTMLDivElement> {
  static component = 'bridge--register-push-notifications'

  connect() {
    super.connect()

    this.send<Record<string, never>, CallbackData>(
      'connect',
      {},
      ({ data: { devicePlatform, registrationId } }) => {
        fetch(`/mobile_devices`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': CSRFToken(),
          },
          body: JSON.stringify({
            device: {
              device_platform: devicePlatform,
              firebase_registration_id: registrationId,
            },
          }),
        })
      },
    )
  }
}
