import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="booking-form"
export default class extends Controller {
  static targets = [
    'multiselectContainer',
    'multiselectOption',
    'emailConsent',
    'smsConsent',
    'bookingForm',
  ]

  declare multiselectContainerTarget: HTMLElement
  declare emailConsentTarget: HTMLInputElement
  declare smsConsentTarget: HTMLInputElement
  declare multiselectOptionTargets: HTMLInputElement[]
  declare multiselectOptionTarget: HTMLInputElement
  declare bookingFormTarget: HTMLFormElement

  validate(event: SubmitEvent) {
    try {
      if (this.multiselectContainerTarget) {
        this.validateMultiselectComponent({
          event,
          multiselectContainer: this.multiselectContainerTarget,
        })
      }
    } catch (error) {
      // do nothing
    }

    if (this.emailConsentTarget || this.smsConsentTarget) {
      this.validateConsent({
        event,
        emailConsentTarget: this.emailConsentTarget,
        smsConsentTarget: this.smsConsentTarget,
      })
    }
  }

  validateMultiselectComponent({
    event,
    multiselectContainer,
  }: {
    event: SubmitEvent
    multiselectContainer: HTMLElement
  }) {
    if (multiselectContainer.dataset.required === 'true') {
      const atLeastOneChecked = this.multiselectOptionTargets.some((option) => option.checked)

      if (!atLeastOneChecked) {
        multiselectContainer.classList.remove('border-neutral-400')
        multiselectContainer.classList.add('border-error-500')

        // TODO: add eventListener to display HTML error popup on field
        this.multiselectOptionTarget.focus()
        event.preventDefault()
      } else {
        multiselectContainer.classList.add('border-neutral-400')
        multiselectContainer.classList.remove('border-error-500')
      }
    }
  }

  validateConsent({
    event,
    emailConsentTarget,
    smsConsentTarget,
  }: {
    event: SubmitEvent
    emailConsentTarget: HTMLInputElement
    smsConsentTarget: HTMLInputElement
  }) {
    const consentGiven = emailConsentTarget.checked || smsConsentTarget.checked

    if (!consentGiven) {
      event.preventDefault()
      this.dispatch('openNoConsentWarningModal')
    }
  }

  activateNotifications() {
    if (this.emailConsentTarget) {
      this.emailConsentTarget.checked = true
    }

    if (this.smsConsentTarget) {
      this.smsConsentTarget.checked = true
    }

    this.dispatch('closeNoConsentWarningModal')
  }

  continueWithoutNotifications() {
    this.dispatch('closeNoConsentWarningModal')

    this.bookingFormTarget.requestSubmit()
  }
}
