import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

import {
  loadDeviceKeyPairForPassword,
  reencryptAndUploadPatientUserMasterKeyDataWithPassword,
} from '../encryption/encryption'

/**
 * ChangeEncryptionPasswordController allow changing the encryption password and reencrypts the master
 * keys so the patient has still access to the encrypted data
 */
export default class ChangeEncryptionPasswordController extends Controller<HTMLFormElement> {
  static targets = [
    'currentPasswordInput',
    'currentPasswordHelper',
    'newPasswordInput',
    'confirmPasswordInput',
    'passwordsMatch',
  ]

  static values = {
    currentPasswordInvalid: String,
  }

  async setPassword(event: CustomEvent) {
    event.preventDefault()

    if (!(await this.validateCurrentPassword())) {
      return
    }

    await reencryptAndUploadPatientUserMasterKeyDataWithPassword(this.newPasswordInputTarget.value)
    await loadDeviceKeyPairForPassword(this.newPasswordInputTarget.value)

    Turbo.visit('/?changed_password=true', { action: 'replace' })
  }

  removeCurrentPasswordError() {
    this.currentPasswordHelperTarget.textContent = null
    this.setBorderColor('border-info-600', this.currentPasswordInputTarget)
  }

  addInvalidCurrentPasswordError() {
    this.currentPasswordHelperTarget.textContent = this.currentPasswordInvalidValue
    this.setTextColor('text-error-500', this.currentPasswordHelperTarget)
    this.setBorderColor('border-error-500', this.currentPasswordInputTarget)
  }

  async validateCurrentPassword() {
    const deviceKey = await loadDeviceKeyPairForPassword(this.currentPasswordInputTarget.value)
    if (!deviceKey) {
      this.addInvalidCurrentPasswordError()
      return false
    }
    return true
  }

  setTextColor(color: string, element: HTMLElement) {
    element.classList.remove('text-info-700')
    element.classList.remove('text-success-700')
    element.classList.remove('text-error-500')

    element.classList.add(color)
  }

  setBorderColor(color: string, element: HTMLElement) {
    element.classList.remove('border-info-600')
    element.classList.remove('border-error-500')

    element.classList.add(color)
  }

  declare currentPasswordInputTarget: HTMLInputElement
  declare newPasswordInputTarget: HTMLInputElement
  declare confirmPasswordInputTarget: HTMLInputElement
  declare passwordsMatchTarget: HTMLElement
  declare currentPasswordHelperTarget: HTMLElement

  declare currentPasswordInvalidValue: string
}
