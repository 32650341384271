import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

import {
  loadDeviceKeyPairForRecoveryKey,
  reencryptAndUploadPatientUserMasterKeyDataWithPassword,
} from '../encryption/encryption'

// Connects to data-controller="reset-password"
export default class extends Controller<HTMLFormElement> {
  static targets = ['recoveryKeyInput', 'newPasswordInput', 'recoveryKeyHelper']

  static values = { recoveryKeyInvalid: String }

  async setPassword(event: CustomEvent) {
    const password = this.newPasswordInputTarget.value

    if (!(await this.validateRecoveryKey())) {
      return
    }

    await reencryptAndUploadPatientUserMasterKeyDataWithPassword(password)

    Turbo.visit('/?changed_password=true', { action: 'replace' })
  }

  async validateRecoveryKey() {
    const deviceKey = await loadDeviceKeyPairForRecoveryKey(this.recoveryKeyInputTarget.value)

    if (!deviceKey) {
      this.addInvalidRecoveryKeyError()
      return false
    }
    return true
  }

  addInvalidRecoveryKeyError() {
    this.recoveryKeyHelperTarget.textContent = this.recoveryKeyInvalidValue
    this.setTextColor('text-error-500', this.recoveryKeyHelperTarget)
    this.setBorderColor('border-error-500', this.recoveryKeyHelperTarget)
  }

  setTextColor(color: string, element: HTMLElement) {
    element.classList.remove('text-info-750')
    element.classList.remove('text-success-700')
    element.classList.remove('text-error-500')

    element.classList.add(color)
  }

  setBorderColor(color: string, element: HTMLElement) {
    element.classList.remove('border-info-600')
    element.classList.remove('border-error-500')

    element.classList.add(color)
  }

  removeRecoveryKeyError() {
    this.recoveryKeyHelperTarget.textContent = null
    this.setBorderColor('border-info-600', this.recoveryKeyInputTarget)
  }

  connect() {}

  declare recoveryKeyInputTarget: HTMLInputElement
  declare newPasswordInputTarget: HTMLInputElement
  declare recoveryKeyHelperTarget: HTMLElement

  declare recoveryKeyInvalidValue: string
}
